@import "~antd/dist/antd.less";

/* .login-box {
  display: flex;
  justify-content: center;
  align-items: center;
} */

.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 0, 0, 0.3);
}
.ant-menu-overflow.ant-menu-horizontal{
  justify-content: flex-end;
}
.login_form_wrapper{
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  .ant-form-item-control-input-content{
    height: 3em;
  }
  .ant-input-affix-wrapper{
    height: 100%;
  }
}
.raymond_login_logo_wrapper{
  // background: red;
  // margin-bottom: 2.5em;
  padding: 11px 10px;
}


iframe #buttonDockRight{
  display: none !important;
}

.webviewer {
  flex: 1;
  margin: 8px;
  -webkit-box-shadow: 1px 1px 10px #999;
          box-shadow: 1px 1px 10px #999;
          width: 100%;
          height: 70vh;
}



.ant-descriptions-item-label{
  width: 50%;
}




